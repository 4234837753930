#projects {
  margin-top: 0px;
}

#projects h1 {
  font-size: 3rem;
  margin-top: 0px;
  margin-bottom: 20px;
  font-weight: 600;
}

#projects h2 {
  text-align: center;
}

#projects p {
  text-align: center;
}

.project-grid {
  display: flex;
  justify-content: space-around;
  flex-wrap: nowrap;
  padding-top: 80px;
  width: 80%;
  margin: 0 auto;
}

.project {
  background-color: #333;
  padding: 20px;
  margin: 20px;
  width: 30%;
  box-sizing: border-box;
  border-radius: 10px;
  text-align: left;
}

.project img {
  width:85%;
  border-radius: 8px;
  margin-bottom: 10px;
  display: block;
  margin: 0 auto;
  transition: transform 0.3s ease;
}

.project img:hover {
  transform: scale(1.05);
}

.project h2 {
  margin-top: 20px;
}

.project p {
  margin: 0.5rem 0;
  margin-bottom: 20px;
}

.project ul {
  padding-left: 1.5rem;
}

.project a {
  color: #61dafb;
  text-decoration: none;
}

.project a:hover {
  text-decoration: underline;
}

.tech-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 2px;
  justify-content: center;
}

.tech-button {
  background-color: rgb(36, 34, 34);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.875rem;
  font-weight: medium;
  text-align: center;
  user-select: none;
}