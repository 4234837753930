nav {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  box-sizing: border-box;
  height: 60px;
}

nav ul li a {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
  cursor: pointer;
}

nav .logo {
  font-size: 1.5rem;
  font-weight: bold;
}

nav .logo a {
  color: white;
  text-decoration: none;
}

nav ul {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
  margin-left: auto;
}

nav ul li {
  margin: 0 1rem;
}

nav ul li a {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
}