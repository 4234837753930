@import './styles/Navbar.css';
@import './styles/About.css';
@import './styles/Projects.css';

html {
  scroll-behavior: smooth;
}

body {
  background-color: rgb(20, 20, 20);
  color: white;
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
}

section {
  padding: 100px 20px;
  text-align: center;
}