#about {
  min-height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 10px;
}

.about-container {
  position: relative;
  padding: 80px;
  border-radius: 30px;
  overflow: hidden;
  box-sizing: border-box;
}

.about-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 30px;
  padding: 5px;
  background: radial-gradient(circle at 12.3% 19.3%, rgb(85, 88, 218) 0%, rgb(95, 209, 249) 100.2%);
  background-size: 300% 300%;
  animation: gradient-border 3s ease-in-out infinite;
  -webkit-mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: subtract;
  pointer-events: none;
}

@keyframes gradient-border {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

#about h1 {
  font-size: 3.5rem;
  margin-bottom: 15px;
}

#about p {
  font-size: 1.75rem;
  margin-top: 0;
}

.view-projects-button {
  display: inline-block;
  margin-top: 60px;
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  text-decoration: none;
  transition: background 1s ease, transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  box-sizing: border-box;
}

.view-projects-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 10px;
  padding: 3px;
  background: radial-gradient(circle at 12.3% 19.3%, rgb(85, 88, 218) 0%, rgb(95, 209, 249) 100.2%);
  background-size: 300% 300%;
  animation: gradient-border 3s ease-in-out infinite;
  -webkit-mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: subtract;
  pointer-events: none;
}

.view-projects-button:hover::before {
  background: radial-gradient(circle at 12.3% 19.3%, rgb(85, 88, 218) 0%, rgb(95, 209, 249) 100.2%);
  animation: none;
}

.view-projects-button:hover {
  transform: scale(1.05);
  border-radius: 10px;
  background: radial-gradient(circle at 12.3% 19.3%, rgb(85, 88, 218) 0%, rgb(95, 209, 249) 100.2%);
}